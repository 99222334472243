import request from '@/utils/request';

// 获取菜单
export function GetMenu(params) {
  return request({
    url: `/admin/collocation/menu/menu/getTreeList`,
    method: 'GET',
    params
  });
}

// 新增菜单

export function SaveMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/save`,
    method: 'POST',
    data,
  });
}

// 菜单详情
export function DetailMenu(params) {
  return request({
    url: '/admin/collocation/menu/menu/detail',
    method: 'GET',
    params
  });
}

// 更新菜单
export function UpdateMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/update`,
    method: 'POST',
    data
  });
}

// 删除域
// export function DeleteArea(params) {
//   return request({
//     url: '/admin/collocation/menu/menu/delete',
//     method: 'GET',
//     params
//   });
// }

// 新增菜单
// export function SaveMenu(data) {
//   return request({
//     url: '/admin/collocation/menu/menu/save',
//     method: 'POST',
//     data
//   });
// }

// 更新菜单
// export function UpdateMenu(data) {
//   return request({
//     url: `/admin/collocation/menu/menu/update`,
//     method: 'POST',
//     data,
//   });
// }

// 删除菜单
export function DeleteMenu(params) {
  return request({
    url: '/admin/collocation/menu/menu/delete',
    method: 'GET',
    params
  });
}

// 菜单详情
// export function DetailMenu(data) {
//   return request({
//     url: `/admin/collocation/menu/menu/detail`,
//     method: 'GET',
//     params: data,
//   });
// }
