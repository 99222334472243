<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <div class="menu_page" style="padding-top: 20px">
        <div style="display: flex; height: 100%">
          <div style="width: 400px; padding: 20px; border: 1px solid #eeeeee">
            <div>
              <a-button
                type="dashed"
                style="color: green; margin-right: 10px"
                @click="rollback"
                ><a-icon type="rollback" /> 返回</a-button
              >
              <a-button type="dashed" style="color: green" @click="createArea"
                ><a-icon type="plus-circle" /> 创建菜单</a-button
              >
            </div>
            <a-divider />
            <div>
              <a-tree
                :showLine="true"
                :defaultExpandAll="true"
                :tree-data="menuList"
                @select="selectTreeNode"
                :replaceFields="{
                  title: 'name',
                  key: 'id',
                  children: 'children',
                }"
              />
            </div>
          </div>
          <div
            style="
              flex: 1;
              padding: 20px;
              border: 1px solid #eeeeee;
              margin-left: 10px;
            "
          >
            <div>
              <a-button type="dashed" style="color: green" @click="createMenu"
                ><a-icon type="plus-circle" /> 添加子菜单</a-button
              ><a-divider type="vertical" />
              <a-button type="dashed" style="color: #108ee9" @click="editMenu"
                ><a-icon type="edit" /> 编辑菜单</a-button
              ><a-divider type="vertical" />
              <a-button
                type="dashed"
                style="color: #f50"
                @click="deleteMenu"
                v-if="currentSelectNode.children == undefined"
                ><a-icon type="close-circle" /> 删除菜单</a-button
              ><a-divider type="vertical" />
              <a-button type="dashed" style="color: green" @click="createBtn"
                ><a-icon type="plus-circle" /> 添加按钮</a-button
              >
            </div>
            <a-divider />
            <a-alert
              v-if="formVisibleType == 'create-area'"
              message="当前在执行创建域操作"
              type="info"
              show-icon
              style="margin-bottom: 30px"
            />
            <a-alert
              v-if="formVisibleType == 'edit-area'"
              message="当前在执行编辑域操作"
              type="info"
              show-icon
              style="margin-bottom: 30px"
            />
            <a-alert
              v-if="formVisibleType == 'create-menu'"
              message="当前在执行添加菜单操作"
              type="info"
              show-icon
              style="margin-bottom: 30px"
            />
            <a-alert
              v-if="formVisibleType == 'create-btn'"
              message="当前在执行添加按钮操作"
              type="info"
              show-icon
              style="margin-bottom: 30px"
            />
            <a-alert
              v-if="formVisibleType == 'edit-menu'"
              message="当前在执行编辑菜单操作"
              type="info"
              show-icon
              style="margin-bottom: 30px"
            />
            <a-form-model
              v-if="formVisibleType"
              ref="formModel"
              :rules="formModelRules"
              :model="formModel"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-model-item label="名称" prop="name">
                <a-input placeholder="请输入名称" v-model="formModel.name" />
              </a-form-model-item>
              <a-form-model-item
                label="前端路由地址"
                prop="path"
                v-show="!isAddbtn"
              >
                <a-input
                  placeholder="请输入前端路由地址"
                  v-model="formModel.path"
                />
              </a-form-model-item>
              <a-form-model-item label="后端路由地址" prop="grant_urls">
                <a-textarea
                  placeholder="请输入后端路由地址"
                  v-model="formModel.grant_urls"
                  :rows="6"
                />
              </a-form-model-item>
              <a-form-model-item
                label="类型"
                :defaultValue="formModel.type"
                prop="type"
              >
                <a-radio-group button-style="solid" v-model="formModel.type">
                  <a-radio-button
                    value="1"
                    :disabled="formModel.type == '1' ? false : true"
                    >按扭</a-radio-button
                  >
                  <a-radio-button
                    value="0"
                    :disabled="formModel.type == '0' ? false : true"
                    >菜单</a-radio-button
                  >
                  <!-- <a-radio-button :value="2" :disabled="formModel.type == '2' ? false : true">域</a-radio-button> -->
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="菜单图标"
                prop="icon"
                v-show="!isAddbtn"
              >
                <a-input
                  placeholder="请输入菜单图标"
                  v-model="formModel.icon"
                />
              </a-form-model-item>
              <a-form-model-item label="序号" prop="sort" v-show="!isAddbtn">
                <a-input
                  placeholder="请输入序号"
                  v-model="formModel.sort"
                  type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="是否分配" prop="allocation">
                <a-radio-group
                  button-style="solid"
                  v-model="formModel.allocation"
                >
                  <a-radio-button value="0">分配</a-radio-button>
                  <a-radio-button value="1">不分配</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="是否显示"
                prop="is_show"
                v-if="isAddbtn && formModel.allocation == 0"
              >
                <a-radio-group button-style="solid" v-model="formModel.is_show">
                  <a-radio value="0">是</a-radio>
                  <a-radio value="1">否</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="前端组件路径"
                prop="componentPath"
                v-show="!isAddbtn"
              >
                <a-input
                  placeholder="请输入前端组件路径"
                  v-model="formModel.componentPath"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
                <a-button
                  type="primary"
                  @click="actionData"
                  :loading="$store.state.requestLoading"
                  >确定</a-button
                >
                <a-button style="margin-left: 20px" @click="resetForm"
                  >重置</a-button
                >
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "./api";
import ATextarea from "ant-design-vue/es/input/TextArea";
export default {
  components: { ATextarea },
  data() {
    return {
      platformId: "", // 平台ID
      isAddbtn: false,
      menuList: [],
      formModel: {
        name: "",
        path: "",
        grant_urls: "",
        type: "",
        icon: "",
        sort: "",
        allocation: "",
        componentPath: "",
        is_show: "0",
      },
      formModelRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        path: [
          { required: true, message: "请输入前端路由地址", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        icon: [
          { required: true, message: "请输入菜单图标", trigger: "change" },
        ],
        sort: [{ required: true, message: "请输入排序数", trigger: "change" }],
        allocation: [
          { required: true, message: "请选择是否分配", trigger: "change" },
        ],
        componentPath: [
          { required: true, message: "请输入前端组件路径", trigger: "change" },
        ],
        is_show: [
          { required: true, message: "请选择是否显示", trigger: "change" },
        ],
      },
      actionVisible: false,
      currentSelectNode: "",
      formVisibleType: "",
      submitFlag: true,
    };
  },
  mounted() {
    this.$route.params.platform_id != undefined &&
      window.localStorage.setItem("platformId", this.$route.params.platform_id);
    this.platformId =
      this.$route.params.platform_id != undefined
        ? this.$route.params.platform_id
        : window.localStorage.getItem("platformId");
    this.getMenuList({ platform_id: this.platformId });
  },
  methods: {
    // 返回上级页面
    rollback() {
      this.$router.push("/system-config/platform-manager");
    },
    // 创建一级菜单
    createArea() {
      this.isAddbtn = false; // 是否是按钮添加
      this.resetFormModel();
      this.currentSelectNode = "";
      this.formVisibleType = "create-menu";
      this.formModel.platform_id = this.platformId;
      this.formModel.pid = 0;
      this.formModel.type = "0";
    },
    // 添加子菜单
    createMenu() {
      this.isAddbtn = false; // 是否是按钮添加
      this.resetFormModel();
      this.formVisibleType = "create-menu";
      this.formModel.platform_id = this.platformId;
      this.formModel.pid = this.currentSelectNode["id"];
      this.formModel.type = "0";
    },

    // 编辑菜单
    async editMenu() {
      this.isAddbtn = false; // 是否是按钮添加
      this.formVisibleType = "edit-menu";
      let params = {
        id: this.currentSelectNode.id,
        platform_id: this.platformId,
      };
      let res = await Api.DetailMenu(params);
      this.formModel = res.target;
    },

    // 按钮添加
    createBtn() {
      this.isAddbtn = true;
      this.formVisibleType = "create-btn";
      this.formModel = {
        name: "",
        path: "x",
        grant_urls: "",
        type: "",
        icon: "x",
        sort: "x",
        allocation: "",
        componentPath: "x",
        is_show: "",
      };
      this.formModel.platform_id = this.platformId;
      this.formModel.pid = this.currentSelectNode["id"];
      this.formModel.type = "1";
      console.log(this.formModel);
    },

    // 删除菜单
    deleteMenu() {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let params = {
            id: that.currentSelectNode.id,
            platform_id: that.platformId,
          };
          let res = await Api.DeleteMenu(params);
          if (res && res["code"] == "0") {
            //that.deleteNode(that.menuList, that.currentSelectNode.id);
            that.getMenuList({ platform_id: that.platformId });
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    // 添加节点
    addNode(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          if (item.menuChildren) {
            item.menuChildren.push(obj);
          } else {
            item["menuChildren"] = [obj];
          }
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.addNode(item.menuChildren, id, obj);
        }
      }
    },

    // 编辑节点
    editNode(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr[i] = obj;
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.editNode(item.menuChildren, id, obj);
        }
      }
    },

    // 添加，更新
    actionData() {
      let that = this;
      if (this.submitFlag) {
        this.$refs.formModel.validate(async (valid) => {
          if (valid) {
            let res = null;
            this.submitFlag = false;
            console.log(this.formVisibleType);
            if (
              this.formVisibleType == "create-menu" ||
              this.formVisibleType == "create-btn"
            ) {
              // 新增菜单
              res = await Api.SaveMenu(this.formModel);
            } else if (this.formVisibleType == "edit-menu") {
              // 编辑菜单
              res = await Api.UpdateMenu(this.formModel);
            }

            if (res && res["code"] == "0") {
              // 创建菜单
              if (this.formVisibleType == "create-menu") {
                this.menuList.push(res["obj"]);
              }
              // 编辑菜单
              else if (this.formVisibleType == "edit-menu") {
                this.editNode(
                  this.menuList,
                  this.currentSelectNode["id"],
                  res["obj"]
                );
              }
              this.$message.success(res.message);
              this.actionVisible = false;
              this.getMenuList(this.$route.params);
              this.formVisibleType = "";
            } else {
              // that.$message.error(res.message);
            }

            this.submitFlag = true;
          }
        });
      }
    },

    // 删除节点
    deleteNode(arr, id) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr = arr.splice(i, 1);
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.deleteNode(item.menuChildren, id);
        }
      }
    },

    // 选择菜单节点
    selectTreeNode(key, event) {
      this.formVisibleType = "";
      console.log(event);
      this.currentSelectNode = event.node.dataRef;
      console.log(this.currentSelectNode.children);
    },

    // 重置当前操作的状态
    resetState() {
      this.resetFormModel();
      this.currentSelectNode = "";
      this.formVisibleType = "";
    },
    // 重置表单
    resetForm() {
      this.formModel = {
        name: "",
        path: "",
        grant_urls: "",
        type: "",
        icon: "",
        sort: "",
        allocation: "",
        componentPath: "",
      };
    },
    // 清空表单数据
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },

    // 菜单列表
    async getMenuList(data) {
      const res = await Api.GetMenu(data);
      this.menuList = res.treeList;
    }
  },
};
</script>

<style lang="less">
.menu_page {
  height: 100%;
  .panel {
    height: 100%;
    margin-top: 20px;
    &:first-child {
      border-right: 1px solid #eaeaea;
    }
    &:last-child {
      padding-left: 20px;
    }
  }
  .ant-row {
    height: 100%;
  }
}
</style>
